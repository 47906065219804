import { forwardRef } from 'react';
import { IconWrapper } from '../style';
import type { IconProps } from '../types';

export const Info = forwardRef<HTMLDivElement, IconProps>(
  ({ color, rotation, width = '24px', height = '24px', cursor }, ref) => (
    <IconWrapper ref={ref} width={width} height={height} cursor={cursor}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        transform={`rotate(${rotation ?? 0})`}
      >
        <circle cx="12" cy="12" r="10" stroke={color ?? 'currentColor'} strokeWidth="2" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 9C12.5523 9 13 8.55228 13 8C13 7.44772 12.5523 7 12 7C11.4477 7 11 7.44772 11 8C11 8.55228 11.4477 9 12 9ZM13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12V16C11 16.5523 11.4477 17 12 17C12.5523 17 13 16.5523 13 16V12Z"
          fill={color ?? 'currentColor'}
        />
      </svg>
    </IconWrapper>
  ),
);

Info.displayName = 'Info';
