import { createContext, useContext, useMemo, type ReactNode } from 'react';
import { type PartnerTreeNodeV2 } from '@stenarecycling/customer-portal-types';
import { InactiveStateProvider } from './useInactiveState';

type LocationPickerProviderProps = {
  children: ReactNode;
  locations: PartnerTreeNodeV2[];
  hideInactiveLocations: boolean;
  showInactiveToggle: boolean;
  onInactiveToggle?: (hideInactive: boolean) => void;
  locationCountV2?: boolean;
};

type LocationPickerConfigState = {
  locationCountV2?: boolean;
};

const LocationPickerConfigContext = createContext<LocationPickerConfigState | undefined>(undefined);

export const LocationPickerProvider = (props: LocationPickerProviderProps) => {
  const locationPickerConfigValue = useMemo(
    () => ({ locationCountV2: props.locationCountV2 }),
    [props.locationCountV2],
  );

  return (
    <LocationPickerConfigContext.Provider value={locationPickerConfigValue}>
      <InactiveStateProvider
        locations={props.locations}
        showInactiveToggle={props.showInactiveToggle}
        hideInactiveLocations={props.hideInactiveLocations}
        onInactiveToggle={props.onInactiveToggle}
      >
        {props.children}
      </InactiveStateProvider>
    </LocationPickerConfigContext.Provider>
  );
};

export const useLocationPickerConfig = (): LocationPickerConfigState => {
  const context = useContext(LocationPickerConfigContext);

  if (context === undefined) {
    throw new Error('useLocationPickerConfig must be used within a LocationPickerProvider');
  }

  return context;
};
