import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

import { Close } from '#icons/Close';
import { colors } from '../../../themes/stena-recycling/colors';
import { Paragraph } from '../../Typography/Paragraph';
import { Link } from '../../Navigation/Link';
import { IconButton } from '../../Input/IconButton';
import { CloseButtonWrapper, MessageBox, MessageContainer, TextWrapper } from './styles';

const mappedColorMessageTypes = (type: string) => {
  switch (type) {
    case 'success':
      return colors.alerts.successTint3;
    case 'info':
      return colors.alerts.infoTint3;
    case 'error':
      return colors.alerts.errorTint3;
    case 'warning':
      return colors.alerts.warningTint3;
    default:
      return colors.alerts.infoTint3;
  }
};

const mappedBorderMessageTypes = (type: string) => {
  switch (type) {
    case 'success':
      return colors.alerts.success;
    case 'info':
      return colors.alerts.info;
    case 'error':
      return colors.alerts.error;
    case 'warning':
      return colors.alerts.warning;
    default:
      return colors.alerts.info;
  }
};

type MessageProps = {
  title?: string;
  description?: string;
  children?: React.ReactNode;
  severity?: 'success' | 'info' | 'error' | 'warning';
  show?: boolean;
  mt?: string;
  mb?: string;
  link?: {
    href: string;
    text: string;
  };
  tight?: boolean;
  closable?: boolean;
  onClose?: () => void;
};

export const Message = ({
  title,
  description,
  link,
  severity = 'info',
  show = true,
  children,
  mb,
  mt,
  tight,
  closable,
  onClose,
}: MessageProps) => {
  const { t } = useTranslation();

  const [showMessage, setShowMessage] = useState<boolean | null>();

  useEffect(() => {
    setShowMessage(show);
  }, [show]);

  const handleClose = () => {
    setShowMessage(false);
    if (onClose) {
      onClose();
    }
  };

  const defaultDescription =
    severity === 'error' ? t('message.components.message.error.default.text') : '';

  if (!showMessage) {
    return null;
  }

  return (
    <MessageContainer
      type={mappedColorMessageTypes(severity)}
      border={mappedBorderMessageTypes(severity)}
      mb={mb}
      mt={mt}
    >
      <MessageBox tight={tight}>
        <TextWrapper>
          {title && (
            <Paragraph variant="large" bold mb="tiny">
              {title}
            </Paragraph>
          )}
          {!children && (
            <>
              <Paragraph variant="large">{description ?? defaultDescription}</Paragraph>
              {link && <Link href={link.href}>{link.text}</Link>}
            </>
          )}
          {children}
        </TextWrapper>
        {closable && (
          <CloseButtonWrapper>
            <IconButton onClick={handleClose} variant="secondary">
              <Close />
            </IconButton>
          </CloseButtonWrapper>
        )}
      </MessageBox>
    </MessageContainer>
  );
};

export const ErrorMessage = ({ title, description, mb, mt, link }: MessageProps) => {
  return (
    <Message title={title} description={description} severity="error" mb={mb} mt={mt} link={link} />
  );
};
