import { type ReactNode } from 'react';
import { IconLeft, IconRight, StyledLink } from './style';

export type LinkProps = {
  href: string;
  preventDefault?: boolean;
  onClick?: () => void;
  underline?: boolean;
  underlineOnHover?: boolean;
  light?: boolean;
  children?: ReactNode;
  disabled?: boolean;
  bold?: boolean;
  inline?: boolean;
  target?: string;
  rel?: string;
  icon?: ReactNode;
  iconPlacement?: 'left' | 'right';
};

export const Link = ({
  href,
  preventDefault = false,
  underline,
  underlineOnHover = true,
  light = false,
  onClick,
  icon,
  children,
  disabled,
  bold = false,
  inline = false,
  iconPlacement = 'right',
  ...rest
}: LinkProps) => {
  return (
    <StyledLink
      data-testid={`link-${light ? 'darkmode' : 'lightmode'}-${
        underline ? 'underline' : 'no-underline'
      }-${disabled ? 'disabled' : 'enabled'}`}
      href={href}
      underline={underline}
      underlineOnHover={underlineOnHover}
      light={light}
      disabled={disabled}
      bold={bold}
      inline={inline}
      icon={icon}
      onClick={(e) => {
        if (onClick || preventDefault) {
          e.preventDefault();
          if (onClick) {
            onClick();
          }
        }
      }}
      {...rest}
    >
      {icon && iconPlacement === 'left' && <IconLeft>{icon}</IconLeft>}
      {children}
      {icon && iconPlacement === 'right' && <IconRight>{icon}</IconRight>}
    </StyledLink>
  );
};
