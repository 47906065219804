import { useFlag } from '@unleash/proxy-client-react';

export const useNBP = () => {
  const _isNBPPilot = useFlag('nbp-pilot');
  const nbpOptOut = useFlag('nbp-pilot-opt-out');

  const isNBPPilot = _isNBPPilot && !nbpOptOut;

  return {
    isNBPPilot,
  };
};
