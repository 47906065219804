import styled from '@emotion/styled';
import { typography, colors, spacing } from '../../../../themes';
import { Textfield } from '../../Text/Textfield';

export const InputWrapper = styled.div`
  position: relative;
`;

export const IconWrapper = styled.div<{ disabled?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: ${spacing.mediumHigh};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

export const DatepickerWrapper = styled.div`
  display: flex;
  flex-flow: column;
  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }
`;

export const StyledTextfield = styled(Textfield)`
  padding-right: ${spacing.mediumHigh};
  cursor: pointer;
`;

export const LabelWrapper = styled.div`
  display: flex;
  fled-direction: row;
  gap: ${spacing.tiny};
`;

export const DatePickerLabel = styled.label`
  ${typography.other.inputLabel};
  margin-bottom: ${spacing.tiny};
  color: ${colors.primary.grey};
`;

export const RequiredIndicator = styled.div`
  color: ${colors.primary.grey};
`;

export const DatePickerInput = styled.input`
  ${typography.other.inputValue}
  width: 100%;
  margin-left: 12px;
  margin-bottom: 0;
  letter-spacing: 0;
  border: none;
  outline: none;
  font-family: ${typography.fontFamily};
  cursor: pointer;
`;

export const DateInputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.xxsmall};
`;

export const DateInput = styled.input`
  ${typography.other.inputValue}
  border: none;
  width: 86px;
`;

export const DatePickerHeader = styled.div`
  ${typography.heading.desktop.small};
  font-family: ${typography.fontFamily};
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${spacing.xsmall};
  max-width: 272px;
`;

export const DatePickerMonthLabel = styled.p`
  margin-bottom: 0px;
  margin-top: 0px;
  font-family: ${typography.fontFamily};
  :first-letter {
    text-transform: uppercase;
  }
`;

export const StyledFormLabel = styled.p`
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 4px;
`;

export const HighlightedDatesLabel = styled.p`
  font-size: 16px;
  line-height: 24px;
  position: relative;
  color: ${colors.base.black};
  padding-left: 18px;

  &:before {
    content: '';
    position: absolute;
    height: 8px;
    width: 8px;
    background-color: ${colors.primary.blueTint2};
    border-radius: 50%;
    top: 8px;
    left: 0;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${colors.monochrome.grey20};
  margin: ${spacing.xxsmall} 0;
`;

export const ErrorMessageBox = styled.div`
  height: 22px;
  ${typography.body.medium}
  color: ${colors.alerts.error};
  margin-top: ${spacing.tiny};
`;
