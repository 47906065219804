import { useEffect, useRef } from 'react';
import { useFlagsStatus } from '@unleash/proxy-client-react';
import { useProfileV2 } from '#lib/Profile';
import { useNBP } from '#lib/hooks/useNBP';

export const NbpPilotFlagReset = () => {
  const { flagsReady } = useFlagsStatus();
  const { isNBPPilot } = useNBP();
  const previousNbpPilot = useRef<boolean | null>(null);
  const profileContext = useProfileV2();

  useEffect(() => {
    if (!flagsReady) return;

    // If flag changes during the session, update the profile to load the new partners and permissions
    if (previousNbpPilot.current !== null && previousNbpPilot.current !== isNBPPilot) {
      void profileContext.updateProfile();
    }

    previousNbpPilot.current = isNBPPilot;
  }, [flagsReady, isNBPPilot, profileContext]);

  return null;
};
