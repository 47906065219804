import styled from '@emotion/styled';
import { breakpoint, colors, spacing, typography } from '../../../../../../themes';

export const QuickRangeWrapper = styled.div`
  margin-right: ${spacing.small};
  /* margin-left: ${spacing.xsmall};
  margin-top: ${spacing.xsmall};
  margin-bottom: ${spacing.xsmall}; */
  padding-right: ${spacing.small};
  border-right: 1px solid #d6d7d9;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: ${spacing.xxsmall};

  @media (max-width: ${breakpoint.medium}px) {
    border-right: none;
    border-top: 1px solid #d6d7d9;
    padding: ${spacing.xmsmall} 0 0;
    margin: ${spacing.xmsmall} 0 0;
    align-items: center;
  }
`;

export const PeriodButton = styled.button`
  ${typography.body.large};
  border-radius: ${spacing.tiny};
  border: 1px solid ${colors.primary.blue};
  color: ${colors.primary.blue};
  cursor: pointer;
  padding: ${spacing.tiny} ${spacing.xxsmall};
  background-color: transparent;
  letter-spacing: 0.5px;
  white-space: nowrap;

  &:hover {
    background-color: #e6e6e6;
  }

  &:active {
    background-color: #d6d7d9;
  }
`;
