import React from 'react';
import {
  useMergeRefs,
  FloatingPortal,
  FloatingFocusManager,
  useTransitionStyles,
} from '@floating-ui/react';
import { RemoveScroll } from 'react-remove-scroll';
import { Close } from '#icons/Close';
import { Button } from '../../Input/Button';
import { IconButton } from '../../Input/IconButton';
import { ModalBody, ModalFooter, ModalHeader, ModalInner, OverlayWrapper } from './styles';
import { ModalContext, useModal, useModalContext } from './ModalContext';
import { type ModalOptions } from './types';

export function ModalV2({
  children,
  onClose,
  onOpenChange,
  ...options
}: {
  children: React.ReactNode;
} & ModalOptions) {
  const _onOpenChange = (isOpen: boolean) => {
    if (!isOpen) {
      onClose?.();
    }
  };

  const dialog = useModal({ ...options, onClose, onOpenChange: onOpenChange ?? _onOpenChange });

  return (
    <ModalContext.Provider value={dialog}>
      <ModalContent>{children}</ModalContent>
    </ModalContext.Provider>
  );
}

const ModalContent = React.forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement>>(
  function DialogContent(props, propRef) {
    const {
      context: floatingContext,
      getFloatingProps,
      primaryAction,
      secondaryAction,
      onClose,
      disableClose,
      maxWidth,
      fullScreen,
      refs,
    } = useModalContext();
    const ref = useMergeRefs([refs.setFloating, propRef]);

    const { styles, isMounted } = useTransitionStyles(floatingContext, {
      duration: {
        open: 200,
        close: 250,
      },
    });

    if (!floatingContext.open && !isMounted) return null;

    return (
      <FloatingPortal>
        <OverlayWrapper>
          <FloatingFocusManager context={floatingContext}>
            <RemoveScroll>
              <ModalBody
                ref={ref}
                {...getFloatingProps(props)}
                style={styles}
                fullScreen={fullScreen}
                maxWidth={maxWidth}
              >
                {!disableClose && (
                  <ModalHeader>
                    <IconButton
                      variant="secondary"
                      onClick={() => {
                        onClose?.();
                      }}
                      data-testid="close-modal-button"
                    >
                      <Close />
                    </IconButton>
                  </ModalHeader>
                )}
                <ModalInner topPadding={disableClose}>{props.children}</ModalInner>
                {primaryAction && (
                  <ModalFooter>
                    {secondaryAction && (
                      <Button
                        variant="tertiary"
                        preventDoubleClick={true}
                        onClick={() => {
                          void secondaryAction.action();
                        }}
                        isLoading={secondaryAction.isLoading}
                        disabled={secondaryAction.disabled}
                        data-testid="secondary-action-button"
                      >
                        {secondaryAction.label}
                      </Button>
                    )}
                    <Button
                      variant="primary"
                      preventDoubleClick={true}
                      onClick={() => {
                        void primaryAction.action();
                      }}
                      icon={primaryAction.icon}
                      iconPlacement="after"
                      isLoading={primaryAction.isLoading}
                      disabled={primaryAction.disabled}
                      data-testid="primary-action-button"
                    >
                      {primaryAction.label}
                    </Button>
                  </ModalFooter>
                )}
              </ModalBody>
            </RemoveScroll>
          </FloatingFocusManager>
        </OverlayWrapper>
      </FloatingPortal>
    );
  },
);
