import { type ReactNode, useState, type MouseEvent, useRef } from 'react';
import { usePopper } from 'react-popper';
import { Info } from '#icons/Info';
import { colors } from '../../../themes/stena-recycling/colors';
import { spacing } from '../../../themes/stena-recycling/spacing';
import { Spacer, TooltipContent, TooltipWrapper } from './style';

type TooltipProps = {
  children: ReactNode;
  hoverItem?: ReactNode;
  isRight?: boolean;
  mousePosX?: (mousePosX: number) => void;
};

const DefaultTooltipIcon = () => (
  <Info color={colors.primary.grey} width={spacing.small} height={spacing.small} />
);

export const Tooltip = ({ children, hoverItem, isRight, mousePosX }: TooltipProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toolTipCloseTimeOutHandler = useRef<ReturnType<typeof setTimeout>>();

  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement);

  const open = (e: MouseEvent) => {
    if (mousePosX) {
      mousePosX(e.clientX);
    }
    clearTimeout(toolTipCloseTimeOutHandler.current);
    setIsOpen(true);
  };

  const close = () => {
    if (toolTipCloseTimeOutHandler.current) {
      clearTimeout(toolTipCloseTimeOutHandler.current);
    }
    toolTipCloseTimeOutHandler.current = setTimeout(() => {
      setIsOpen(false);
    }, 200);
  };

  return (
    <TooltipWrapper
      ref={setReferenceElement}
      onClick={open}
      onMouseEnter={open}
      onMouseLeave={close}
    >
      {hoverItem || <DefaultTooltipIcon />}
      <Spacer>
        <TooltipContent
          ref={setPopperElement}
          isOpen={isOpen}
          isRight={isRight}
          style={styles.popper}
          {...attributes.popper}
        >
          {children}
        </TooltipContent>
      </Spacer>
    </TooltipWrapper>
  );
};
