import { type CSSProperties, type ReactNode, useRef } from 'react';
import { Transition, type TransitionStatus } from 'react-transition-group';
import { Close } from '#icons/Close';
import { modalTransition } from '../../helpers/styleHelpers';
import { Button } from '../../Input/Button';
import { IconButton } from '../../Input/IconButton';
import useModal from './useModal';
import {
  ModalBody,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  ModalInner,
  ModalWrapper,
} from './styles';

type ModalAction = {
  label: string;
  action: (() => void) | (() => Promise<void>);
  icon?: ReactNode;
  isLoading?: boolean;
  disabled?: boolean;
};

export type ModalProps = {
  children: ReactNode;
  show: boolean;
  onClose?: () => void;
  fullScreen?: boolean;
  closeOnClickOutside?: boolean;
  secondaryButton?: string;
  primaryAction?: ModalAction;
  styleInner?: CSSProperties;
  maxWidth?: string;
};

export function Modal({
  children,
  show,
  fullScreen,
  secondaryButton,
  primaryAction,
  onClose,
  closeOnClickOutside = false,
  styleInner = {},
  maxWidth,
}: ModalProps) {
  const { resetBody } = useModal({ onClose, show, closeOnClickOutside });

  const modalRef = useRef(null);
  const defaultStyle = {
    transition: `opacity ${modalTransition}`,
    opacity: 0,
  };
  const transitionStyles: Record<string, CSSProperties> = {
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
  };

  return (
    <Transition
      in={show}
      timeout={{
        appear: 0,
        enter: 200,
        exit: 250,
      }}
      nodeRef={modalRef}
      mountOnEnter={true}
      unmountOnExit={true}
    >
      {(state: TransitionStatus) => {
        if (state === 'exited' || state === 'unmounted') {
          resetBody();
        }

        return (
          <div ref={modalRef} style={{ position: 'absolute' }} id="modal">
            <ModalWrapper
              ref={modalRef}
              style={{ ...defaultStyle, ...transitionStyles[state] }}
              data-testid="modal"
            >
              <ModalContainer data-testid="wrapper" id="wrapper">
                <ModalBody fullScreen={fullScreen} maxWidth={maxWidth}>
                  <ModalHeader>
                    <IconButton
                      variant="secondary"
                      onClick={() => {
                        onClose?.();
                      }}
                      data-testid="close-modal-button"
                    >
                      <Close />
                    </IconButton>
                  </ModalHeader>
                  <ModalInner style={styleInner}>{children}</ModalInner>
                  {primaryAction && (
                    <ModalFooter>
                      {secondaryButton && (
                        <Button
                          variant="tertiary"
                          preventDoubleClick={true}
                          onClick={() => {
                            onClose?.();
                          }}
                          data-testid="secondary-action-button"
                        >
                          {secondaryButton}
                        </Button>
                      )}
                      <Button
                        variant="primary"
                        preventDoubleClick={true}
                        onClick={() => {
                          void primaryAction.action();
                        }}
                        icon={primaryAction.icon}
                        iconPlacement="after"
                        isLoading={primaryAction.isLoading}
                        disabled={primaryAction.disabled}
                        data-testid="primary-action-button"
                      >
                        {primaryAction.label}
                      </Button>
                    </ModalFooter>
                  )}
                </ModalBody>
              </ModalContainer>
            </ModalWrapper>
          </div>
        );
      }}
    </Transition>
  );
}
