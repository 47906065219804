import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { typography } from '../../themes';
import { type IconProps } from './types';

export const IconWrapper = styled.div<IconProps>`
  ${({ color, width = '24px', height = '24px', cursor }) => css`
    color: ${color ?? 'inherit'};
    max-width: ${width};
    max-height: ${height};
    cursor: ${cursor ?? 'inherit'};
    display: flex;
    align-items: center;
  `}
`;

export const IconStoryContainer = styled.div`
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 20px;
  flex-wrap: wrap;
`;

export const IconStoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 110px;
  height: 100%;
  padding: 8px;
`;

export const Description = styled.div`
  ${typography.other.inputLabel}
  margin-top: 10px;
`;
